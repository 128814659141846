import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { Col } from "react-bootstrap"

import styled from "styled-components"
import Color, { Palette } from "color-thief-react";


function ProductCard(props) {
  const imgSrc = props.productImage;
  const ProductText = styled.div`
    /* This renders the buttons above... Edit me! */
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: auto;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    writing-mode: vertical-rl;
    margin-top: 2vh;
  `


    const ColorSwatch = styled.div`
    /* This renders the buttons above... Edit me! */
    width:2vw;
    height:2vw;
    max-width:20px;
    max-height:20px;
    background-color:black;
    @media only screen and (min-width: 600px) {

}
    `


  
  return (
    <Col
      className={
        props.orientation
          ? "homepage-item item-left"
          : "homepage-item item-right"
      }
      style={{ backgroundImage: `url(${props.productImage})` }}
      xs={6}
      md={3}
    >
     <Palette src={imgSrc} className="" crossOrigin="anonymous" format="hex" colorCount={4}>
        {({ data, loading }) => {
          if (loading) return <div className="m-5" />;
          return (
            <div className="colorswatch">
                {data && data.map((color, index) => (
                  <ColorSwatch key={index} style={{ backgroundColor: color }}>
                  </ColorSwatch>
                ))}
            </div>
          );
        }}
      </Palette>

      <ProductText className="">
        {" "}
        <p>{props.size}</p> <p>{props.brandname}</p>
        <p>{props.producttitle}</p>
      </ProductText>

    
    </Col>
  )
}

export default ProductCard
