import * as React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// Import Swiper React components 
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container, Col, Row } from 'react-bootstrap'

import ProductCard from "../components/ProductCard"

import 'bootstrap/dist/css/bootstrap.min.css';



import SwiperCore, {
  Lazy
} from 'swiper';

// Import Swiper styles
import 'swiper/css';

const IndexPage = () => (
  <Layout>
    <Container>
      <Seo title="Portfolio" />
      <Row className="homepage-info">
        <ProductCard orientation={'l'} productImage='/per.jpg' size="SIZE L" brandname="PER GOTESSON" producttitle=" VEST BAG" />
        <ProductCard orientation={'r'} productImage='/raf.jpg' size="SIZE M" brandname="RAF SIMONS" producttitle="c-cut sample jacket" />
        <ProductCard orientation={'l'} productImage='/tak.jpg' size="SIZE M" brandname="NUMBER (N)INE" producttitle="PATTERN JACKET" />
        <ProductCard orientation={'r'} productImage='/yoh.jpg' src="" size="SIZE L" brandname="YOHJI" producttitle="CYBER 3 JACKET" />
        <ProductCard orientation={'l'} productImage='/tak.jpg' size="SIZE M" brandname="Walter van Beirendonck" producttitle="PUKPUK TEE" />
        <ProductCard orientation={'r'} productImage='/bal.png' src="" size="SIZE L" brandname="balenciaga" producttitle="c-cut sample jacket" />
        <ProductCard orientation={'l'} productImage='/tak.jpg' size="SIZE M" brandname="Walter van Beirendonck" producttitle="PUKPUK TEE" />
        <ProductCard orientation={'r'} productImage='/bal.png' src="" size="SIZE L" brandname="balenciaga" producttitle="c-cut sample jacket" />
      </Row>

      {/*       
          <StaticQuery
    query={graphql`
    {
        allGraphCmsPiece {
            edges {
              node {
                titlte
                pieceTitle
                productVideo {
                  url
                }
              }
            }
          }
      }
    `}
    render={data => 
        <Swiper
        spaceBetween={50}
        slidesPerView={1}
        autoplay={true}
      >{data.allGraphCmsPiece.edges.map(({ node }) => {
        return ( <><p>{node.titlte}</p><p>{node.pieceTitle}</p><video width="400" controls muted autoplay>
        <source src={node.productVideo.url} type="video/mp4" />
    </video></>)
    })}</Swiper>
}
  ></StaticQuery> */}

    </Container>
  </Layout>
)

export default IndexPage